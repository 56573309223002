<template>
    <b-card-code
        title="Edit Hospital"
    >
    
        <b-row class="d-flex justify-content-center">
            <b-col cols="8">
                <validation-observer ref="hospitalRules">
                    <b-form @submit.prevent>
                    <b-row>
                        <b-col cols="12">
                            <b-form-group
                                label="Name"
                                label-for="name"
                                label-cols-md="4"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="name"
                                    rules="required"
                                    >
                                    <b-form-input
                                    id="name"
                                    placeholder="The name of hospital"
                                    v-model="hospital.name"
                                    :state="errors.length > 0 ? false:null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <validation-provider
                                #default="{ errors }"
                                name="hospital ID"
                                rules="required"
                                >
                                <b-form-group
                                    label="Hospital ID"
                                    label-for="hospital_id"
                                    label-cols-md="4"
                                >
                                    <b-form-input
                                    id="hospital_id"
                                    placeholder="Hospital ID"
                                    v-model="hospital.hospital_id"
                                    :state="errors.length > 0 ? false:null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col cols="12">
                            <validation-provider
                                #default="{ errors }"
                                name="contact email"
                                rules="required|email"
                                >
                                <b-form-group
                                    label="Contact email"
                                    label-for="contact_email"
                                    label-cols-md="4"
                                >
                                    <b-form-input
                                    id="contact_email"
                                    type="email"
                                    placeholder="Contact email"
                                    v-model="hospital.contact_email"
                                    :state="errors.length > 0 ? false:null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col cols="12">
                        <b-form-group
                            label="Contact Info"
                            label-for="contact_info"
                            label-cols-md="4"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="contact info"
                                rules="required"
                                >
                                <b-form-textarea
                                id="contact_info"
                                placeholder="Contact Info"
                                v-model="hospital.contact_info"
                                :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        </b-col>
                        
                        <b-col cols="12">
                            <b-form-group
                                label="Notes"
                                label-for="notes"
                                label-cols-md="4"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="notes"
                                    rules="required"
                                    >
                                    <b-form-textarea
                                    id="notes"
                                    placeholder="Notes"
                                    v-model="hospital.notes"
                                    :state="errors.length > 0 ? false:null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        
                        <!-- submit and reset -->
                        <b-col offset-md="4">
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="submit"
                            variant="secondary"
                            class="mr-1"
                            @click="$router.push({ name: 'admin-hospitals' })"
                        >
                            Back
                        </b-button>
                        <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="reset"
                            variant="outline-secondary"
                            class="mr-1"
                        >
                            Reset
                        </b-button>
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="submit"
                            variant="primary"
                            @click.prevent="handleUpdate"
                        >
                            Submit
                        </b-button>
                        </b-col>
                    </b-row>

                    </b-form>
                </validation-observer>
            </b-col>
        </b-row>
    </b-card-code>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardCode from '@core/components/b-card-code/BCardCode1.vue'
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormTextarea
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, email } from '@validations'

export default {
    components: {
        BCardCode,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton,
        BFormTextarea,
        ValidationProvider, 
        ValidationObserver
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            hospital: {
                name: '',
                hospital_id: '',
                contact_email: '',
                contact_info: '',
                notes: ''
            },
            required,
            email
        }
    },
    mounted() {
        this.fetchHospital()
    },
    methods: {
        fetchHospital() {
            this.$http.get(`/admin/hospitals/read/${this.$route.params.id}`)
                .then(res => {
                    this.hospital = res.data.hospital
                })
                .catch(err => {
                    this.showToast('danger', 'Error', 'An error occured while reading a hospital.')
                })
        },
        handleUpdate() {
            this.$refs.hospitalRules.validate().then((success) => {
                if (success) {
                    this.$http.patch(`/admin/hospitals/update/${this.$route.params.id}`, this.hospital)
                        .then(res => {
                            this.showToast('success', 'Success', 'Successfully Updated.')
                            this.$router.push({ name: 'admin-hospitals' })
                        })
                        .catch(err => {
                            this.showToast('danger', 'Error', 'An error occured while updating a hospital.')
                        })
                }
            })
        },
        showToast(variant, title, content) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: title,
                    icon: 'BellIcon',
                    text: content,
                    variant,
                },
            })
        },
    }
}
</script>
